.quck-links-p{
    color: #fff;
    font-size: 25px;
    margin-bottom: 3rem !important;
    cursor: pointer;
}
.quick-links{
    margin-top: 87px;
}
.quick-links-div-mobile{
    margin-top: 38px;
}
.quck-links-p-mobile{
    color: #fff;
    font-size: 20px;
    margin-bottom: 3rem !important;
    cursor: pointer;
    text-align: center;
}

@media screen and (max-width: 899px) {
    
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
    .quck-links-p {
        color: #fff;
        font-size: 20px;
        margin-bottom: 3rem !important;
        cursor: pointer;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .quck-links-p {
        color: #fff;
        font-size: 20px;
        margin-bottom: 3rem !important;
        cursor: pointer;
    }
}


@media screen and (min-width: 1281px) and (max-width: 1500px) {
    .quck-links-p {
        color: #fff;
        font-size: 22px;
        margin-bottom: 3rem !important;
        cursor: pointer;
    }
}