.footer-logo-div{
    width: 300px;
    height: 300px;
    background-color: #fff;
    margin-top: 81px;
    border-radius: 253px;
}

.footer-logo{
    margin-left: 23px;
    margin-top: 76px;
    width: 82%;
    object-fit: contain;
}

@media screen and (max-width: 899px) {
    
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
    .footer-logo-div {
        width: 190px !important;
        height: 190px !important;
        background-color: #fff !important;
        margin-top: 81px !important;
        border-radius: 50% !important;
    }
    .footer-logo {
        margin-left: 13px !important;
        margin-top: 46px !important;
        width: 85% !important;
        object-fit: contain !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .footer-logo-div {
        width: 190px !important;
        height: 190px !important;
        background-color: #fff !important;
        margin-top: 81px !important;
        border-radius: 50% !important;
    }
    .footer-logo {
        margin-left: 13px !important;
        margin-top: 46px !important;
        width: 85% !important;
        object-fit: contain !important;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1500px) {
    .footer-logo-div {
        width: 190px !important;
        height: 190px !important;
        background-color: #fff !important;
        margin-top: 81px !important;
        border-radius: 50% !important;
    }
    .footer-logo {
        margin-left: 13px !important;
        margin-top: 46px !important;
        width: 85% !important;
        object-fit: contain !important;
    }
}

@media screen and (min-width: 1501px) and (max-width: 1700px) {
    .footer-logo-div {
        width: 240px !important;
        height: 240px !important;
        background-color: #fff !important;
        margin-top: 81px !important;
        border-radius: 50% !important;
    }
    .footer-logo {
        margin-left: 13px !important;
        margin-top: 54px !important;
        width: 85% !important;
        object-fit: contain !important;
    }
}

@media screen and (min-width: 1701px) and (max-width: 1800px) {
    .footer-logo-div {
        width: 240px !important;
        height: 240px !important;
        background-color: #fff !important;
        margin-top: 81px !important;
        border-radius: 50% !important;
    }
    .footer-logo {
        margin-left: 13px !important;
        margin-top: 54px !important;
        width: 85% !important;
        object-fit: contain !important;
    }
}