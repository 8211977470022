.footer {
    background: linear-gradient(to right,
            #E67940 0%,
            #FE5656 50%,
            #D4642A 100%);
    width: 100%;
}

.footer-up-image {
    width: 100%;
    object-fit: contain;
}

.footer-div-logo-icons {
    display: flex;
    gap: 190px;
}

.footer-image {
    display: flex;
    justify-content: end;
    margin-top: -419px;
}

@media screen and (max-width: 899px) {
    
}

@media screen and (min-width: 900px) and (max-width: 1024px) {
    .footer {
        background: linear-gradient(to right, #E67940 0%, #FE5656 50%, #D4642A 100%) !important;
        width: 100% !important;
    }
    .footer-up-image {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: -10px !important;
    }
    .footer-div-logo-icons {
        display: flex !important;
        gap: 38px !important;
    }

}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
    .footer {
        background: linear-gradient(to right, #E67940 0%, #FE5656 50%, #D4642A 100%) !important;
        width: 100% !important;
    }
    .footer-up-image {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: -10px !important;
    }
    .footer-div-logo-icons {
        display: flex !important;
        gap: 89px !important;
    }
    
}

@media screen and (min-width: 1281px) and (max-width: 1500px) {
    .footer {
        background: linear-gradient(to right, #E67940 0%, #FE5656 50%, #D4642A 100%) !important;
        width: 100% !important;
    }
    .footer-up-image {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: -10px !important;
    }
    .footer-div-logo-icons {
        display: flex !important;
        gap: 152px !important;
    }
    
}

@media screen and (min-width: 1501px) and (max-width: 1700px) {
    .footer {
        background: linear-gradient(to right, #E67940 0%, #FE5656 50%, #D4642A 100%) !important;
        width: 100% !important;
    }
    .footer-up-image {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: -10px !important;
    }
    .footer-div-logo-icons {
        display: flex !important;
        gap: 160px !important;
    }
    
}


@media screen and (min-width: 1701px) and (max-width: 1920px) {
    .footer {
        background: linear-gradient(to right, #E67940 0%, #FE5656 50%, #D4642A 100%) !important;
        width: 100% !important;
    }
    .footer-up-image {
        width: 100% !important;
        object-fit: contain !important;
        margin-top: -10px !important;
    }
    .footer-div-logo-icons {
        display: flex !important;
        gap: 200px !important;
    }
    
}